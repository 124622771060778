import styled from 'styled-components';
import { rem } from 'polished';
import { breakpoint } from '../theme';

export const SectionTitle = styled.h2`
  display: block;
  width: 100%;
  margin: 0 0 ${rem(30)} 0;
  font-size: ${rem(24)};
  font-weight: ${({ theme }) => theme.typography.weights.heading.normal};
  font-family: ${({ theme }) => theme.typography.fonts.heading};

  ${breakpoint('large')} {
    font-size: ${rem(30)};
    margin-bottom: ${rem(40)};
  }
`;

export const StyledContent = styled.div`
  h4,
  h5,
  h6 {
    margin: 0 0 ${rem(16)} 0;
    font-size: ${rem(18)};
    text-transform: uppercase;
    letter-spacing: 0.065em;

    ${breakpoint('mediumlarge')} {
      font-size: ${rem(18)};
    }

    ${breakpoint('large')} {
      font-size: ${rem(21)};
    }
  }

  p {
    color: ${(props) => props.theme.palette.colors.greyLight};
  }
`;

export const SectionHeader = styled.div`
  text-align: center;
  /* margin-top: ${rem(0)}; */
  margin-bottom: ${rem(20)};
`;

export const SectionFooter = styled.div`
  text-align: center;
  margin-top: ${rem(25)};
  margin-bottom: ${rem(20)};
`;
