import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Row from '../components/Row';
import Col from '../components/Col';
import Hero from '../components/Hero';

// import useLangRedirect from '../hooks/useLangRedirect';

import { SectionTitle } from '../utils/GlobalFormatting';

const Container = styled.div`
  max-width: ${(props) => props.theme.grid.maxWidthText};
  margin: ${rem(40)} auto 0;
  padding-bottom: ${rem(80)};
`;

function Page() {
  // useLangRedirect(page);

  return (
    <Layout>
      <SEO title="Om oss" />
      <Hero
        heading="Om Publikfigur"
        // image={featuredMedia?.localFile}
        pageTitle
        // preHeading="Pre heading"
        // actions="Actions"
        tint
        // big={heroSize === 'big'}
      />
      <Container>
        <Row>
          <Col>
            <SectionTitle>Stötta din förening med en publikfigur.</SectionTitle>
            <p>
              I en svår tid och restriktionerna hindrar oss att stötta våra lag
              på plats har vi hittat en lösning för er att (nästan) vara på
              matchen ändå.
            </p>
            <p>50% av kostnaden går oavkortat till klubben</p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default Page;
