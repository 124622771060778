/* eslint-disable no-underscore-dangle */
import { useLocation } from '@reach/router';
import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Facebook from './Facebook';
import Twitter from './Twitter';

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl
        siteLanguage
        ogLanguage
        author
        twitter
        facebook
        socialLinks {
          facebook
          instagram
          linkedin
          pinterest
          twitter
          vimeo
          youtube
        }
      }
    }
    logo: file(relativePath: { eq: "logo/logo.svg" }) {
      publicURL
    }
  }
`;

const SEO = ({ title, description, lang, image, pathname, article, node }) => {
  const { pathname: routerPathname } = useLocation();

  return (
    <StaticQuery
      query={`${query}`} // String hack needed to fix https://github.com/birkir/gatsby-source-prismic-graphql/issues/70
    >
      {({ site, logo }) => {
        const {
          siteMetadata: {
            defaultTitle,
            defaultDescription,
            siteUrl,
            siteLanguage,
            ogLanguage,
            author,
            twitter,
            facebook,
            socialLinks,
          },
        } = site;

        const seo = {
          title: title || defaultTitle,
          description: description || defaultDescription,
          image,
          url: `${siteUrl}${pathname || routerPathname}`,
          lang: lang || siteLanguage || 'en',
        };

        const schemas = {
          organization: {
            '@type': 'Organization',
            '@id': `${siteUrl}/#organization`,
            name: author,
            logo: {
              '@type': 'ImageObject',
              '@id': `${siteUrl}/#logo`,
              url: `${siteUrl}${logo.publicURL}`,
              caption: author,
            },
            sameAs: Object.values(socialLinks).filter(Boolean),
          },
        };

        schemas.website = {
          '@type': 'WebSite',
          '@id': `${siteUrl}/#website`,
          url: `${siteUrl}/`,
          name: defaultTitle,
          description: defaultDescription,
          inLanguage: seo.lang,
          copyrightHolder: { '@id': schemas.organization['@id'] },
        };

        if (seo.image) {
          schemas.image = {
            '@type': 'ImageObject',
            '@id': `${seo.url}#primaryimage`,
            inLanguage: seo.lang,
            url: seo.image,
          };
        }

        schemas.webpage = {
          '@type': 'WebPage',
          '@id': `${seo.url}#webpage`,
          url: seo.url,
          isPartOf: { '@id': schemas.website['@id'] },
          inLanguage: seo.lang,
          ...(title && { name: title }),
          ...(description && { description }),
          ...(schemas.image && {
            primaryImageOfPage: { '@id': schemas.image['@id'] },
          }),
        };

        if (article) {
          schemas.article = {
            '@type': 'Article',
            '@id': `${seo.url}#article`,
            url: seo.url,
            isPartOf: { '@id': schemas.webpage['@id'] },
            mainEntityOfPage: { '@id': schemas.webpage['@id'] },
            author: { '@id': schemas.organization['@id'] },
            publisher: { '@id': schemas.organization['@id'] },
            inLanguage: siteLanguage,
            ...(title && { headline: title }),
            ...(description && { description }),
            ...(schemas.image && { image: { '@id': schemas.image['@id'] } }),
            ...(node?._meta?.firstPublicationDate && {
              datePublished: node?._meta?.firstPublicationDate,
            }),
            ...(node?._meta?.lastPublicationDate && {
              dateModified: node?._meta?.lastPublicationDate,
            }),
          };
        }

        return (
          <>
            <Helmet
              title={seo.title}
              titleTemplate={`%s — ${defaultTitle}`}
              htmlAttributes={{ lang: seo.lang }}
            >
              <meta name="description" content={seo.description} />
              <meta name="image" content={seo.image} />
              <script type="application/ld+json">
                {JSON.stringify([
                  {
                    '@context': 'https://schema.org',
                    '@graph': Object.values(schemas),
                  },
                ])}
              </script>
            </Helmet>
            <Facebook
              desc={seo.description}
              image={seo.image}
              title={seo.title}
              type={article ? 'article' : 'website'}
              url={seo.url}
              locale={ogLanguage}
              name={facebook}
            />
            <Twitter
              title={seo.title}
              image={seo.image}
              desc={seo.description}
              username={twitter}
            />
          </>
        );
      }}
    </StaticQuery>
  );
};

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  node: PropTypes.oneOfType([PropTypes.object]),
  lang: PropTypes.string,
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
  node: null,
  lang: '',
};
